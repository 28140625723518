import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { navigateTo } from 'gatsby-link';
import { TinyPagination } from 'react-pagination-custom';
import ReactHtmlParser from 'react-html-parser';
import PagesHero from '../components/PagesHero';
import placeHolderImage from '../images/placeholder.png';
import FilterWidget from '../components/FilterWidget';
import FilterButton from '../components/FilterButton';
import StyledLink from '../components/StyledLink';
import { breakpoints, colors, fonts } from '../style-utilities/variables';

const MainSection = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 30% 70%;
  grid-template-columns: 30% 70%;
  grid-template-areas: 'FilterWidget DisplayedProduct';
  padding: 40px 20px;
  @media (min-width: ${breakpoints.tablet}) {
    grid-template-columns: 350px auto;
  }
  .FilterWidget {
    grid-area: FilterWidget;
    -ms-grid-column: 1;
    grid-column: 1;
    .accordion__body {
      text-align: left;
    }
  }
`;

const DisplayProduct = styled.div`
  -ms-grid-column: 2;
  a {
    text-decoration: none;
  }
  grid-area: DisplayedProduct;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  grid-template-areas:
    'productHeader productHeader'
    'auto auto'
    'auto auto'
    'auto auto'
    'auto auto'
    'auto auto'
    'auto auto'
    'page page';
  grid-auto-columns: 1fr 1fr;

  @media (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-template-areas:
      'productHeader productHeader productHeader'
      'auto auto auto'
      'auto auto auto'
      'auto auto auto'
      'auto auto auto'
      'foot foot page';
    grid-auto-columns: 1fr 1fr 1fr;
  }
  .result-number {
    grid-area: productHeader;
    padding: 0 20px;
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  .SingleProduct {
    display: inline-block;
    vertical-align: top;
    margin: 0 10px;
    text-decoration: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: inline-block;
      width: 270px;
    }
    .product-img {
      min-height: 175px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 175px;

        @media (min-width: 1400px) {
          max-width: 300px;
        }
      }
    }
    p {
      text-decoration: none;
      font-family: ${fonts.secondaryMedium};
      color: ${colors.secondary};
    }
  }
  /* Pagination CSS */
  .tiny-pagination-container {
    display: flex;
    grid-area: page;
    .counter-container {
      display: none;
    }
    justify-content: center;
    @media (min-width: ${breakpoints.tablet}) {
      justify-content: flex-end;
    }
  }
  .btns-number-container {
    display: inherit;
  }
  .counter-container {
    display: none;
    font-size: 18px;
  }
  .page {
    border: none;
    background: transparent;
  }
  .selected-page {
    color: red;
  }
`;

const Div = styled.div`
  .AllProdButton {
    margin-top: 25px;
    #AllProductIcon {
      margin-left: 40px;
      font-size: 20px;
    }
  }
  .hero-section {
    background: ${props => `url(${props.bgImage}) no-repeat center`};
    background-size: cover;
    position: relative;
    .hero-container {
      padding: 0;
    }
    .all-products {
      position: absolute;
      top: 60px;
      left: 40px;
    }
  }
  .Title {
    padding: 20px 0;
  }
  .productTitle {
    color: ${colors.primary};
  }
  .Introduction {
    display: grid;
    grid-template-columns: 45% 10% 45%;
    grid-template-areas: 'mainImage line benefit';

    .image {
      grid-area: mainImage;
      text-align: center;
      width: 100%;
    }
    .image img {
      width: 45vw;
    }
    .Benefit {
      grid-area: benefit;
      padding: 30px;
      /* background: rgba(255, 255, 255, 0.75); */
      .benefit-subheadline {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }

  .FilterButton {
    display: none;
  }

  .next {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    .container {
      padding: 100px 0;
      background: rgba(204, 204, 204, 0.4);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .searchHeader {
        font-size: 36px;
        margin-bottom: 18px;
        font-family: ${fonts.primary};
        font-weight: 600;
        color: ${colors.primary};
      }
    }

    .ButtonSection {
      max-width: 1004px;
      margin: 40px auto;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      div,
      a {
        width: 100%;
        max-width: 350px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    .next {
      text-align: center;
    }

    .ButtonSection {
      display: inline-block;
      /* flex-direction: row; */
      /* justify-content: space-around; */
      div,
      a {
        margin: 0 10px;
        display: inline;
      }
    }
    .hero-section .hero-container {
      padding: 40px;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .all-products {
      display: none;
    }
    .AllProdButton {
      margin-top: 0;
      text-align: center;
    }
    .Introduction {
      display: block;
      img {
        width: 100%;
      }
    }
    ${MainSection} {
      display: block;
      text-align: center;
    }
    ${DisplayProduct} {
      .SingleProduct ul {
        text-align: left;
        height: 100px;
      }
      .counter-container,
      .page {
        font-size: 14px;
      }
    }
    .FilterButton {
      display: block;
      position: sticky;
      bottom: 20px;
      padding: 10px;
      margin: 0 auto 10px;
      font-size: 20px;
      width: 140px;
      svg {
        width: 30px;
        height: 20px;
      }
    }
  }
`;

class ProductCategory extends Component {
  state = {
    filterElements: [],
    selectedPageId: 1,
    isFilterOpen: false,
    currentQueryString: null
  };
  /**
   * Data from CMS
   */
  allTaxonomies = this.props.data.allWordpressWpTaxonomies.edges;
  productCateogory = this.props.data.wordpressWpProductLine;
  allProductCategories = this.props.data.allWordpressWpProductLine.edges;
  allProducts = this.props.data.allWordpressWpProducts.edges;
  currentCategoryId = this.productCateogory.wordpress_id;
  productsUnderCurrentCategory = this.allProducts.filter(item =>
    item.node.product_line.includes(this.currentCategoryId)
  );
  recipeSearchBackground = this.props.data.wordpressPage.acf.search_widget_image
    .source_url;
  //Pasta type
  attribute1 = this.props.data.allWordpressWpAttribute1.edges;
  /**
   * Filter All Categories: Exclude the Current Category
   */
  currentCategory = this.allProductCategories.filter(
    item => this.productCateogory.slug === item.node.slug
  );

  componentDidMount() {
    this.updateFilters();

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    // This simulates the sticky position for the filter button.
    if (isIE11) {
      var filterButton = document.getElementsByClassName('FilterButton')[0];
      var footer = document.getElementsByClassName('footer-wrap')[0];

      filterButton.classList.add('ie-filter-button');

      var body = document.body,
        html = document.documentElement;

      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      var footerHeight = Math.max(
        footer.scrollHeight,
        footer.offsetHeight,
        footer.clientHeight
      );

      var nearBottom =
        height - footerHeight - document.documentElement.clientHeight;

      document.addEventListener('scroll', function() {
        var top =
          (document.documentElement && document.documentElement.scrollTop) ||
          document.body.scrollTop;
        if (
          top > nearBottom &&
          filterButton.classList.contains('ie-filter-button')
        ) {
          filterButton.classList.remove('ie-filter-button');
        }
        if (
          top < nearBottom &&
          !filterButton.classList.contains('ie-filter-button')
        ) {
          filterButton.classList.add('ie-filter-button');
        }
      });
    }
  }

  componentDidUpdate() {
    if (
      this.state.currentQueryString &&
      this.props.location.search !== this.state.currentQueryString
    ) {
      this.updateFilters();
    }
  }

  updateFilters = () => {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    if (search) {
      this.setState({ currentQueryString: search });
    }
    /* Checks to see if a page is in the URL */
    let pagePosition = params.get('page');
    if (pagePosition !== null) {
      this.setState({ selectedPageId: parseInt(pagePosition) });
    }
  };
  /**
   * METHOD: Pass State - filterElements to the FilterWidget Component
   * @param - filters are the filtering options
   */
  setFilters = filters => {
    this.setState({
      filterElements: filters
    });
  };

  /**
   * METHOD: Render products by filtering options
   */
  renderFilteredProduct = () => {
    return this.productsUnderCurrentCategory.map(
      ({ node: { title, attribute_1, acf, slug } }) => {
        //Get slugs for shape
        const attributeOneSlugs = this.attribute1.filter(el =>
          attribute_1.some(e => e === el.node.wordpress_id)
        );
        let display = true;

        if (
          !attributeOneSlugs.some(el =>
            this.state.filterElements.includes(el.node.slug)
          ) &&
          this.attribute1.some(el =>
            this.state.filterElements.includes(el.node.slug)
          )
        ) {
          display = false;
        }
        if (display) {
          return (
            <Link key={slug} to={`/products/${slug}`}>
              <div className="SingleProduct">
                <div className="product-img">
                  {acf.product_image !== null ? (
                    <img
                      src={acf.product_image.source_url}
                      alt="placeHolderImage"
                    />
                  ) : (
                    <img src={placeHolderImage} alt="placeHolderImage" />
                  )}
                </div>
                <div className="productTitle">
                  <h2>{ReactHtmlParser(title)}</h2>
                </div>
              </div>
            </Link>
          );
        }
      }
    );
  };

  /**
   * METHOD: Toggle Filter Widget for Mobile Version
   */
  filterToggleHandler = () => {
    this.setState({ isFilterOpen: !this.state.isFilterOpen });
  };

  /**
   * METHOD: Scroll to top on pagination click
   */
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  /**
   * METHOD: Change page on Pagination
   * @param: id of the current page
   */
  changePage(id) {
    this.setState(
      prevState => {
        return {
          ...prevState,
          selectedPageId: id
        };
      },
      () => {
        const { pathname } = this.props.location;
        const url = `${pathname}?page=${this.state.selectedPageId}`;
        navigateTo(url);
      }
    );
  }

  /**
   * METHOD: for button Prev and Next on Pagination
   * @param: id of the current page
   */
  buttonPageClick(id) {
    let { selectedPageId } = this.state;
    switch (id) {
      case 'Prev':
        this.changePage(selectedPageId - 1);
        break;
      case 'Next':
        this.changePage(selectedPageId + 1);
        break;
      default:
        this.changePage(id);
        break;
    }
    this.scrollToTop();
  }

  /**
   * METHOD: Render button to the UI
   * @param: id of the current page
   */
  renderBtnNumber = id => {
    let { selectedPageId } = this.state;
    return (
      <button
        onClick={this.buttonPageClick.bind(this, id)}
        key={id}
        className={`page ${selectedPageId === id ? 'selected-page' : ''}`}
      >
        {id}
      </button>
    );
  };
  /**
   * Render Buttons Linked to Recipe Search with current category option
   */
  renderCurrentCategoryButton = () => {
    return this.currentCategory.map(({ node: { slug, name } }) => {
      return (
        <div key={slug}>
          <StyledLink link={`/recipe-search?items=${slug}`} key={slug}>
            {ReactHtmlParser(name)}
          </StyledLink>
        </div>
      );
    });
  };

  render() {
    let filterObj;
    this.currentCategory.map(category => {
      if (category.node.slug === 'classic') {
        filterObj = {
          'Pasta Type': this.attribute1.map(item => item)
        };
      } else {
        filterObj = {
          'Pasta Type': this.attribute1
            .filter(
              type => type.node.slug === 'long' || type.node.slug === 'short'
            )
            .map(item => item)
        };
      }
    });

    // filterObj = {
    //   'Pasta Type': this.attribute1.map(item => item)
    // };
    const { name, description, acf } = this.productCateogory;
    const yoast_wpseo_title = this.productCateogory.yoast_head_json.title;
    const yoast_wpseo_description = this.productCateogory.yoast_head_json
      .description;
    //Arrays of results displayed on the UI
    let filteredResult = this.renderFilteredProduct().filter(
      el => typeof el !== 'undefined'
    );

    const itemPerPage = 12;
    // Go back to page 1 if Results are less than 12
    if (filteredResult.length < (this.state.selectedPageId - 1) * itemPerPage) {
      this.changePage(1);
    }
    let filteredProdPerPage = [...filteredResult];
    filteredProdPerPage = filteredProdPerPage.splice(
      (this.state.selectedPageId - 1) * itemPerPage,
      itemPerPage
    );

    return (
      <Div bgImage={acf === null ? placeHolderImage : acf.thumbnail.source_url}>
        <Helmet>
          <title>{ReactHtmlParser(yoast_wpseo_title)}</title>
          <meta
            name="description"
            content={ReactHtmlParser(yoast_wpseo_description)}
          />
        </Helmet>
        <PagesHero className="hero-section" enableOverlay={true}>
          <div className="hero-container">
            <div className="Introduction">
              <div className="Benefit">
                <h1>{ReactHtmlParser(name)}</h1>
                <p className="benefit-subheadline">
                  {ReactHtmlParser(acf.sub_headline)}
                </p>
                <p>{ReactHtmlParser(description)}</p>
                <StyledLink link="/where-to-buy">Where to Buy</StyledLink>
              </div>
            </div>
          </div>
        </PagesHero>
        <MainSection>
          <div className="FilterWidget">
            <FilterWidget
              title="Filter Products"
              state={this.state.filterElements}
              setFilters={this.setFilters}
              filterList={filterObj}
              tagTitle=""
              filterOpen={this.state.isFilterOpen}
              filterToggle={this.filterToggleHandler}
            />
          </div>
          <DisplayProduct>
            <>
              <p className="result-number">{filteredResult.length} products</p>
              {filteredProdPerPage}
              <TinyPagination
                total={filteredResult.length}
                selectedPageId={this.state.selectedPageId}
                itemPerPage={itemPerPage}
                renderBtnNumber={this.renderBtnNumber}
                maxBtnNumbers={10}
                preKey="Prev"
                nextKey="Next"
              />
            </>
          </DisplayProduct>
        </MainSection>
        <FilterButton
          className="FilterButton"
          filterToggle={this.filterToggleHandler}
        />

        <div
          className="next"
          style={{ backgroundImage: `url(${this.recipeSearchBackground})` }}
        >
          <div className="container">
            <p className="searchHeader">Search for Recipes</p>
            {this.renderCurrentCategoryButton()}
          </div>
        </div>
      </Div>
    );
  }
}

export default ProductCategory;

export const query = graphql`
  query($slug: String!) {
    wordpressWpProductLine(slug: { eq: $slug }) {
      wordpress_id
      slug
      name
      description
      acf {
        sub_headline
        thumbnail {
          source_url
        }
      }
      yoast_head_json {
        title
        description
      }
    }
    allWordpressWpTaxonomies {
      edges {
        node {
          name
          slug
          types
        }
      }
    }
    allWordpressWpProductLine {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    allWordpressWpProducts {
      edges {
        node {
          slug
          title
          product_line
          attribute_1
          acf {
            product_image {
              source_url
            }
          }
        }
      }
    }
    allWordpressWpAttribute1 {
      edges {
        node {
          name
          wordpress_id
          slug
        }
      }
    }
    wordpressPage(slug: { eq: "recipe-collection-detail" }) {
      acf {
        search_widget_image {
          source_url
        }
      }
    }
  }
`;
